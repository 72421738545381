import React, { Fragment, useEffect, useState } from "react";

import { withRouter } from "react-router-dom";

import axios from "axios";

import { useRecoilState } from "recoil";
import submenuStore from "../../../store/submenu.store";

import toast from "react-hot-toast";

import loadingCardLength from "../../components/loading/loadingCardLength";
import { BoxLoadingCard } from "../../components/loading/LoadingCard";

import Header from "../../components/Header";

import EmptyIcon from "../../components/icons/EmptyIcon";

import Submenu from "./Submenu";

const SubmenuGridPage: React.FC = (props: any): JSX.Element => {
	if (!props.location.state) window.location.replace("/dashboard");

	const { id, name } = props.location.state;

	const [submenuState, setSubmenuState] = useRecoilState<Array<SubmenuType>>(submenuStore);

	const [isLoadingState, setIsLoadingState] = useState(false);

	function getSubmenuItems(id: string, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			setLoading(true);
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/menu/${id}/child`,
				method: "GET",
				headers: {
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[SUBMENU]', result)

				if (result.status === 200) {
					setLoading(false);
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	useEffect((): void => {
		getSubmenuItems(id, setIsLoadingState).then((result: any): void => {
			const statusCode: number = result.status;
			const data: any = result.data.data;
			if (statusCode === 200) setSubmenuState(data);
		}).catch((e: any): void => {
			toast.error(e.data.message, {
				style: { fontWeight: "bolder" },
			});
			console.log(e);
		});
	}, [id, setSubmenuState]);

	const [searchGridProductMenu, setSearchGridProductMenu] = useState<string>("");

	const ScrollToTopOnMount = (): null => {
		useEffect((): void => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	};

	let productList = ['Games'];

	return (
		<div>
			{productList.includes(name) ? (
				<Header title={name} inputSearch handleSearch={(e) => setSearchGridProductMenu(e.target.value)} />
			) : (
				<Header title={name} />
			)}

			<ScrollToTopOnMount />

			{isLoadingState ? (
				<div className="grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
					{loadingCardLength.map((loading, index) => (
						<Fragment key={index}>
							<BoxLoadingCard />
						</Fragment>
					))}
				</div>
			) : (
				<Fragment>
					{!submenuState.length ? (
						<div className="mt-16 lg:mt-20 2xl:mt-40">
							<EmptyIcon title="Menu belum tersedia, silahkan kembali lagi besok" />
						</div>
					) : (
						<Submenu display="grid" backgroundImage={true} dataSearch={searchGridProductMenu} />
					)}
				</Fragment>
			)}
		</div>
	);
};

export default withRouter(SubmenuGridPage);
