import { Fragment } from "react";

import axios from "axios";

import toast from "react-hot-toast";

import TrxDetails from "../products/prepaid/TrxDetails";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

const purchaseConfirm = async (
	trxType: string,
	trackingId: string,
	productCode: string,
	destination: number,
	counter: number,
	nominal: number,
	pinValidation: string
): Promise<unknown> => {
	let config = {};

	if (trxType === 'prepaid') {
		config = {
			url: `${process.env.REACT_APP_API_ENDPOINT_URL}/trx/prepaid/purchase`,
			method: "POST",
			data: JSON.stringify({
				kode_produk: productCode,
				tujuan: destination,
				counter,
				pin: pinValidation,
				nominal,
			}),
			headers: {
				"Content-Type": "application/json",
				Authorization: localStorage.getItem("user_token"),
			},
			validateStatus: (): boolean => true,
		};
	}

	if (trxType === 'postpaid') {
		console.log('pin validation : ', )
		config = {
			url: `${process.env.REACT_APP_API_ENDPOINT_URL}/trx/postpaid/purchase`,
			method: "POST",
			data: JSON.stringify({
				tracking_id: trackingId,
				pin: pinValidation,
				nominal: nominal
			}),
			headers: {
				"Content-Type": "application/json",
				Authorization: localStorage.getItem("user_token"),
			},
			validateStatus: (): boolean => true,
		}
	}

	return new Promise((resolve, reject): void => {
		axios(config).then((result: any): void => {
			console.log('[CONFIG]:', config)
			console.log(`[PURCHASE CONFIRM ${trxType}]`, result)

			if (result.status === 200) {
				resolve(result);
			} else {
				reject(result);
			}
		}).catch((error: any): void => console.log("STATUS CODE", error));
	})
};


const PinTrxModalValidation = async (
	trxType: string,
	trackingId: string,
	productCode: string,
	destination: number,
	counter: number,
	nominal: number
): Promise<void> => {
	const { value: swalPinValidation } = await Swal.fire({
		title: "Validasi PIN",
		allowOutsideClick: false,
		showCancelButton: true,
		confirmButtonText: "Lanjutkan",
		cancelButtonText: "Batal",
		customClass: {
			title: "font-inter font-semibold text-lg sm:text-2xl text-gray-800",
			confirmButton: "text-sm sm:text-base font-inter",
			cancelButton: "text-sm sm:text-base font-inter",
		},
		html: (
			<Fragment>
				<span className="text-sm sm:text-base font-medium font-inter">Silahkan Validasi PIN Terlebih Dahulu.</span>
				<div className="w-full flex justify-center">
					<input
						onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
							if (!/[0-9]/.test(e.key)) e.preventDefault();
						}}
						type="text"
						id="swal-input1"
						className="password swal2-input text-base sm:text-lg font-medium font-inter w-1/2 text-center"
						pattern="[0-9]*"
						autoFocus
						maxLength={parseInt(process.env.REACT_APP_LOGIN_PIN_LENGTH ?? '')}
						autoComplete="off"
					/>
				</div>
			</Fragment>
		),
		focusConfirm: false,
		preConfirm: (): Array<string> => [
			(document.getElementById("swal-input1") as HTMLInputElement).value,
		],
	});

	async function pinTrxValidation(pinValidationInput: string): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/pin/validate`,
				method: "POST",
				data: JSON.stringify({ pin: pinValidationInput }),
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result): void => {
				// console.log('[PIN TRX VALIDATION]', result)

				if (result.status === 200) {
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error): void => console.log("STATUS CODE", error));
		});
	};

	if (swalPinValidation) {
		const swalPinValidationInput = swalPinValidation[0]
		pinTrxValidation(swalPinValidationInput).then((): void => {
			toast.promise(purchaseConfirm(trxType, trackingId, productCode, destination, counter, nominal, swalPinValidationInput), {
				loading: "Tunggu Sebentar, Kami Sedang Memproses Pesanan Anda",
				success: (result: any): string => {
					const dataValidate: any = result.data.data;

					TrxDetails(dataValidate);

					const message: string = result.data.message;
					if (message) return message;

					return "Pembayaran berhasil";
				},
				error: (e: any): string => {
					let message = e.data.message;
					return message;
				},
			},
				{
					style: {
						fontWeight: "bolder",
					},
				}
			);
		}).catch((e: any): void => {
			let message: string = e.data.message;
			toast.error(message, {
				duration: 4000,
				style: {
					fontWeight: "bolder",
				},
			});
		});
	}
};

export default PinTrxModalValidation;
