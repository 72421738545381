import { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import axios from "axios";

import toast from "react-hot-toast";

import { generateDeviceToken } from "../../modules";

import SendOtpIcon from "../components/icons/SendOtpIcon";
import MessageIcon from "../components/icons/MessageIcon";
import WhatsAppIcon from "../components/icons/WhatsAppIcon";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "./SendOtp.css";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

const SendOtpSmsButton: React.FC = () => {
	const [disableButtonState, setDisableButtonState] = useState<boolean>(false);

	const disableTime: number = 60000;

	let [counterDisableButtonState, setCounterDisableButtonState] = useState<number>(60);

	useEffect((): void => {
		if (!counterDisableButtonState) setDisableButtonState(false);
	}, [counterDisableButtonState]);

	function sendSmsOtp(sms: string, setDisableButton: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		setDisableButton(true);
		return new Promise((resolve, reject): void => {
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/login/send-otp`,
				method: "POST",
				data: JSON.stringify({
					validate_id: localStorage.getItem("validate_login_id"),
					type: sms,
				}),
				headers: {
					"Content-Type": "application/json",
					merchantcode: `${process.env.REACT_APP_API_DATA_MERCHANT_CODE}`,
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[SEND OTP SMS CODE]', result)

				if (result.status === 200)
					resolve(result);
				else
					reject(result);

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	function vericationSmsOtp(phone: string, otpVerificationInputValue: string, validateId: string): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			const payload = {
				phone,
				otp: otpVerificationInputValue,
				validate_id: validateId,
			};

			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/login/validate`,
				method: "POST",
				data: JSON.stringify(payload),
				headers: {
					"Content-Type": "application/json",
					merchantcode: `${process.env.REACT_APP_API_DATA_MERCHANT_CODE}`,
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[OTP SMS VERIFICATION]', result)

				const userToken: string = result.data.data;
				if (result.status === 200) {
					localStorage.setItem("user_token", userToken);
					localStorage.setItem("device_token", generateDeviceToken(200));
					localStorage.removeItem("validate_sentotp_id");
					localStorage.removeItem("phone_sentotp");
					localStorage.removeItem("sms_otp");
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	async function handleSubmitSmsOtp(sms: string, setDisableButton: React.Dispatch<React.SetStateAction<boolean>>, disableTime: number): Promise<void> {
		if (!counterDisableButtonState) setCounterDisableButtonState(counterDisableButtonState += 60);
		setInterval((): void => {
			if (counterDisableButtonState > 0) setCounterDisableButtonState((counterDisableButtonState -= 1));
		}, 1000);

		toast.promise(sendSmsOtp(sms, setDisableButton), {
			loading: "Sedang mengirim kode OTP",
			success: (result: any): string => {
				const { validate_id: validateId, phone } = result.data;

				async function inputVericationSmsOtp(): Promise<void> {
					const { value: swalOtpVerification } = await Swal.fire<Array<string>>({
						title: "Verifikasi OTP",
						timer: disableTime,
						timerProgressBar: true,
						showCloseButton: true,
						allowOutsideClick: false,
						confirmButtonText: "Konfirmasi",
						customClass: {
							title: "font-inter font-bold",
							confirmButton: "font-inter",
						},
						html: <VerifSmsOtpModal />,
						focusConfirm: false,
						preConfirm: (): Array<string> => [
							(document.getElementById("swal-input1") as HTMLInputElement).value,
						],
					});

					if (swalOtpVerification) {
						const swalOtpVerificationValue = swalOtpVerification[0];
						if (!swalOtpVerificationValue) {
							toast.error("OTP Tidak Boleh Kosong", {
								style: {
									fontWeight: "bolder",
								},
							});

							inputVericationSmsOtp();
						} else {
							localStorage.setItem("sms_otp", swalOtpVerificationValue);
	
							toast.promise(vericationSmsOtp(phone, swalOtpVerificationValue, validateId), {
								loading: "Mengecek verifikasi OTP",
								success: (result: any): string => {
									const message: string = result.data.message;
									setTimeout((): void => {
										toast.loading("Mengalihkan Ke Halaman Dashboard", {
											style: { fontWeight: "bolder" },
										});
									}, 2000);
	
									setTimeout((): void => {
										window.location.replace("/dashboard");
									}, 5000);
	
									return message;
								},
								error: (e: any): string => {
									let message = e.data.message;
	
									inputVericationSmsOtp();
									return message;
								},
							},
								{
									style: {
										fontWeight: "bolder",
									},
								}
							);
						}
					}
				};

				inputVericationSmsOtp();

				setTimeout(() => {
					const statusCode: number = result.data.status;

					if (statusCode === 200) {
						return toast.success("OTP Berhasil Dikirim", {
							style: { fontWeight: "bolder" },
						});
					}

					toast.error("OTP Gagal Dikirim", {
						style: { fontWeight: "bolder" },
					});
				}, 4000);

				return "Silahkan Buka Kode OTP Di Pesan Kamu";
			},
			error: (e: any): string => e.data.message,
		},
			{
				style: {
					fontWeight: "bolder",
				},
			}
		);
	};

	return (
		<div className="w-full flex lg:mb-2">
			{disableButtonState ? (
				/* DISABLED SMS BUTTON */
				<button
					className="inline-flex justify-center items-center gap-3 w-full mt-3 py-3.5 px-10 lg:px-20 text-sm sm:text-base font-medium uppercase rounded-sm focus:outline-none cursor-not-allowed"
					style={{ backgroundColor: "#cccccc", color: "#ffffff" }}>
					<MessageIcon />
					Kirim melalui SMS ({counterDisableButtonState})
				</button>
			) : (
				/* ENABLED SMS BUTTON */
				<button onClick={(): Promise<void> => handleSubmitSmsOtp("sms", setDisableButtonState, disableTime)}
					className="inline-flex justify-center items-center gap-3 w-full mt-3 py-3.5 px-10 lg:px-20 text-white text-sm sm:text-base font-medium uppercase rounded-sm focus:outline-none"
					style={{ backgroundColor: "#613c1a" }}>
					<MessageIcon />
					Kirim melalui SMS
				</button>
			)}
		</div >
	);
};

const VerifSmsOtpModal: React.FC = (): JSX.Element => {
	const [otpVerificationState, setOtpVerificationState] = useState<string>("");

	return (
		<Fragment>
			<span className="font-inter">
				Kami telah mengirimkan anda pesan SMS berupa kode OTP, silahkan masukan 4 digit kode OTP untuk melanjutkan proses masuk.
			</span>
			<div className="w-full flex justify-center">
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
						const re = /^[0-9\b]+$/;
						if (e.target.value === "" || re.test(e.target.value)) setOtpVerificationState(e.target.value);
					}}
					type="text"
					id="swal-input1"
					className="swal2-input sm:w-1/2 text-center font-semibold"
					value={otpVerificationState ?? ""}
					maxLength={parseInt(process.env.REACT_APP_SEND_OTP_CONFIRM_LENGTH ?? "")}
					autoFocus
				/>
			</div>
		</Fragment>
	);
};

const SendOtpWhatsAppButton: React.FC = () => {
	const [disableButtonState, setDisableButtonState] = useState<boolean>(false);

	const disableTime: number = 60000;

	let [counterDisableButtonState, setCounterDisableButtonState] = useState<number>(60);

	useEffect((): void => {
		if (!counterDisableButtonState) setDisableButtonState(false);
	}, [counterDisableButtonState]);

	function sendWhatsAppOtp(wa: string, setDisableButton: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
		setDisableButton(true);
		return new Promise((resolve, reject): void => {
			const payload = {
				validate_id: localStorage.getItem("validate_login_id"),
				type: wa,
			};

			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/login/send-otp`,
				method: "POST",
				data: JSON.stringify(payload),
				headers: {
					"Content-Type": "application/json",
					merchantcode: `${process.env.REACT_APP_API_DATA_MERCHANT_CODE}`,
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[SEND OTP WA CODE]', result)

				if (result.status === 200)
					resolve(result);
				else
					reject(result);

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	function vericationWhatAppOtp(phone: string, otpVerificationInputValue: string, validateId: string): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/login/validate`,
				method: "POST",
				data: JSON.stringify({
					phone,
					otp: otpVerificationInputValue,
					validate_id: validateId,
				}),
				headers: {
					"Content-Type": "application/json",
					merchantcode: `${process.env.REACT_APP_API_DATA_MERCHANT_CODE}`,
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log('[OTP WA VERIFICATION]', result)

				const userToken: string = result.data.data;
				if (result.status === 200) {
					localStorage.setItem("user_token", userToken);
					localStorage.setItem("device_token", generateDeviceToken(200));
					localStorage.removeItem("validate_sentotp_id");
					localStorage.removeItem("phone_sentotp");
					localStorage.removeItem("whatsapp_otp");
					resolve(result);
				} else {
					reject(result);
				}
			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	async function handleSubmitWhatsAppOtp(wa: string, setDisableButton: React.Dispatch<React.SetStateAction<boolean>>, disableTime: number) {
		if (!counterDisableButtonState) setCounterDisableButtonState(counterDisableButtonState += 60);
		setInterval((): void => {
			if (counterDisableButtonState > 0) setCounterDisableButtonState((counterDisableButtonState -= 1));
		}, 1000);

		toast.promise(sendWhatsAppOtp(wa, setDisableButton), {
			loading: "Sedang mengirim kode OTP",
			success: (result: any): string => {
				const { validate_id: validateId, phone } = result.data;

				const inputVericationWhatsAppOtp = async (): Promise<void> => {
					const { value: swalOtpVerification } = await Swal.fire<Array<string>>({
						title: "Verifikasi OTP",
						timer: disableTime,
						timerProgressBar: true,
						showCloseButton: true,
						allowOutsideClick: false,
						confirmButtonText: "Konfirmasi",
						customClass: {
							title: "font-inter font-bold",
							confirmButton: "font-inter font-bold",
						},
						html: <VerifWhatsAppOtpModal />,
						focusConfirm: false,
						preConfirm: (): Array<string> => {
							return [
								(document.getElementById("swal-input1") as HTMLInputElement).value,
							];
						},
					});

					if (swalOtpVerification) {
						const swalOtpVerificationValue = swalOtpVerification[0];
						if (!swalOtpVerificationValue) {
							toast.error("OTP Tidak Boleh Kosong", {
								style: { fontWeight: "bolder" },
							});

							inputVericationWhatsAppOtp();
						} else {
							localStorage.setItem("whatsapp_otp", swalOtpVerificationValue);
	
							toast.promise(vericationWhatAppOtp(phone, swalOtpVerificationValue, validateId), {
								loading: "Mengecek Verifikasi OTP",
								success: (result: any): string => {
									const message = result.data.message;
									setTimeout((): void => {
										toast.loading("Mengalihkan Ke Halaman Dashboard", {
											style: {
												fontWeight: "bolder",
											},
										});
									}, 2000);
	
									setTimeout(() => {
										window.location.replace("/dashboard");
									}, 5000);
	
									return message;
								},
								error: (e: any): string => {
									let message = e.data.message;
	
									inputVericationWhatsAppOtp();
									return message;
								},
							},
								{
									style: {
										fontWeight: "bolder",
									},
								}
							);
						}
					}
				};

				inputVericationWhatsAppOtp();

				setTimeout((): any => {
					const statusCode: number = result.data.status;

					if (statusCode === 200) {
						return toast.success("OTP Berhasil Dikirim", {
							style: {
								fontWeight: "bolder",
							},
						});
					}

					toast.error("OTP gagal Dikirim", {
						style: {
							fontWeight: "bolder",
						},
					});
				}, 2000);

				return "Silahkan Buka Kode OTP Di Pesan WhatsApp Kamu";
			},
			error: (e: any): string => e.data.message,
		},
			{
				style: {
					fontWeight: "bolder",
				},
			}
		);
	};

	return (
		<div className="w-full flex">
			{disableButtonState ? (
				/* DISABLED WHATSAPP BUTTON */
				<button
					className="inline-flex justify-center items-center gap-3 w-full mt-6 py-3.5 px-8 lg:px-20 text-sm sm:text-base font-medium uppercase rounded-sm focus:outline-none cursor-not-allowed"
					style={{ backgroundColor: "#cccccc", color: "#ffffff" }}>
					<WhatsAppIcon />
					Kirim melalui Whatsapp ({counterDisableButtonState})
				</button>
			) : (
				/* ENEBLED WHATSAPP BUTTON */
				<button
					onClick={(): Promise<void> => handleSubmitWhatsAppOtp("whatsapp", setDisableButtonState, disableTime)}
					className="inline-flex justify-center items-center gap-3 w-full mt-6 py-3.5 px-8 lg:px-20 text-white text-sm sm:text-base font-medium uppercase rounded-sm focus:outline-none"
					style={{ backgroundColor: "#1bd741" }}>
					<WhatsAppIcon />
					Kirim melalui WhatsApp
				</button>
			)}
		</div>
	);
};

const VerifWhatsAppOtpModal: React.FC = (): JSX.Element => {
	const [otpVerificationState, setOtpVerificationState] = useState<string>("");

	return (
		<Fragment>
			<span className="font-inter">
				Kami telah mengirimkan anda pesan WhatsApp berupa kode OTP, silahkan masukan 4 digit kode OTP untuk melanjutkan proses masuk.
			</span>
			<div className="w-full flex justify-center">
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
						const re = /^[0-9\b]+$/;
						if (e.target.value === "" || re.test(e.target.value)) setOtpVerificationState(e.target.value);
					}}
					type="text"
					id="swal-input1"
					className="swal2-input sm:w-1/2 text-center font-semibold"
					value={otpVerificationState ?? ""}
					maxLength={parseInt(process.env.REACT_APP_SEND_OTP_CONFIRM_LENGTH ?? "")}
					autoFocus
				/>
			</div>
		</Fragment>
	);
};

const BackLoginButton: React.FC = () => {
	const history = useHistory();

	function handleClickBackToLogin() {
		localStorage.clear();
		history.push("/auth/login");
	}

	return (
		<div className="w-full flex justify-center">
			{/* RETURN BACK TO LOGIN PAGE */}
			<button
				onClick={(): void => handleClickBackToLogin()}
				className="inline-flex justify-center items-center gap-3 mt-6 text-sm sm:text-base font-medium underline focus:outline-none">
				Kembali Ke Login
			</button>
		</div>
	);
};

const SendOtpPage: React.FC = (): JSX.Element => {
	return (
		<div className="min-h-screen w-full flex justify-center items-center">
			<div className="w-full px-6 sm:p-0 sm:w-1/2 animate__animated animate__fadeIn flex justify-center items-center flex-col gap-2 sm:gap-6">
				<div className="flex flex-col items-center text-center">
					{/* SEND OTP SVG ICON */}
					<SendOtpIcon />

					<div className="mb-3 sm:my-4">
						{/* SEND OTP TITLE */}
						<span className="font-bold text-xl sm:text-3xl text-center">Kirim Code Verifikasi OTP</span>
					</div>
					<div>
						{/* SEND OTP DESCRIPTION */}
						<span className="text-base sm:text-lg">
							Kami akan mengirimkan anda 4 digit kode OTP untuk melanjutkan proses masuk, silahkan pilih salah satu metode untuk menerima kode OTP dari kami
						</span>
					</div>
				</div>

				<div className="flex flex-col items-center">
					<SendOtpSmsButton />
					<SendOtpWhatsAppButton />
					<BackLoginButton />
				</div>
			</div>
		</div>
	);
};

export default SendOtpPage;
