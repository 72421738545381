import { Fragment, useState } from "react";

import axios from "axios";
import toast from "react-hot-toast";

import { Alfamart, BankEwallet, Qris, VirtaulAccount } from "../deposit/modal-response";

import Select from 'react-select'

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

interface VirtualAccountProps {
	title: string;
	type: number;
	loading: boolean;
	submitLoading: boolean;
	setSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
	data?: Array<any>;
}

const VirtualAccount: React.FC<VirtualAccountProps> = ({
	title,
	type,
	loading,
	submitLoading,
	setSubmitLoading,
	data,
}): JSX.Element => {
	const nominalPintar = [
		{ value: '10000', label: 'Rp 10.000' },
		{ value: '20000', label: 'Rp 20.000' },
		{ value: '30000', label: 'Rp 30.000' },
		{ value: '40000', label: 'Rp 40.000' },
		{ value: '50000', label: 'Rp 50.000' },
		{ value: '75000', label: 'Rp 75.000' },
		{ value: '100000', label: 'Rp 100.000' },
		{ value: '150000', label: 'Rp 150.000' },
		{ value: '200000', label: 'Rp 200.000' },
		{ value: '300000', label: 'Rp 300.000' },
		{ value: '400000', label: 'Rp 400.000' },
		{ value: '600000', label: 'Rp 600.000' },
		{ value: '700000', label: 'Rp 700.000' },
		{ value: '800000', label: 'Rp 800.000' },
		{ value: '900000', label: 'Rp 900.000' },
		{ value: '1000000', label: 'Rp 1.000.000' },
	];

	const handleSubmitDeposit = (
		type: number,
		vacode: string,
		channel: string,
		nominalInput: string | null,
		setSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>
	): Promise<unknown> => {
		return new Promise((resolve, reject): void => {
			let urlApi: string = '';
			let payload: any = {};

			if (type === 1 || type === 2) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/send`;
				payload = {
					nominal: parseInt(nominalInput ?? ''),
					type,
				};
			}

			if (type === 4) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/payment-channel`;
				payload = {
					nominal: parseInt(nominalInput ?? ''),
					type: 6,
					vaname: 5,
				}
			}

			if (type === 5) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/payment-va`;
				payload = {
					nominal: parseInt(nominalInput ?? ''),
					vacode,
				}
			}

			if (type === 7) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/payment-link`;
				payload = {
					nominal: parseInt(nominalInput ?? ''),
					type: channel,
				}
			}

			if (type === 8) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/payment-qris`;
				payload = {
					nominal: parseInt(nominalInput ? nominalInput : ""),
				}
			}

			setSubmitLoading(true);
			axios({
				url: urlApi,
				method: "POST",
				data: JSON.stringify(payload),
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status: any): boolean => true,
			}).then((result: any): void => {
				console.log('[DEPOSIT BY ...]', result)

				if (result.status === 200) {
					setSubmitLoading(false);
					resolve(result);
				} else {
					setSubmitLoading(false);
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	function responseDetails(type: number, data: any): void {
		/*
			TYPE 1 === BANK
				TYPE 2 === EWALLET
			TYPE 4 === INDOMART
			TYPE 5 === ALFAMART
			TYPE 7 === PAYMENT LINK
			TYPE 8 === QRIS
		 */

		if (type === 7) {
			const { url_payment: urlPayment } = data;
			Swal.close();
			window.open(urlPayment, "_blank");
			return;
		}

		Swal.fire({
			padding: "1rem 0rem",
			showConfirmButton: false,
			showCancelButton: true,
			cancelButtonText: "Tutup",
			allowOutsideClick: false,
			heightAuto: false,
			customClass: {
				title: "font-inter font-semibold text-2xl text-gray-800",
				cancelButton: "text-sm sm:text-base font-inter",
				confirmButton: "text-sm sm:text-base font-inter",
			},
			html: (
				<div className="flex flex-col justify-center items-center mb-4">
					{type === 1 || type === 2 ? (
						<Fragment>
							<BankEwallet data={data} />
							<div className="mt-4">
								<span className="font-dmsans text-red-500 font-medium">Harap Transfer Sesuai Nominal</span>
							</div>
						</Fragment>
					) : type === 5 ? (
						<Fragment>
							<VirtaulAccount data={data} />
						</Fragment>
					) : type === 4 ? (
						<Fragment>
							<Alfamart data={data} />
						</Fragment>
					) : type === 8 ? (
						<Fragment>
							<Qris data={data} />
						</Fragment>
					) : (
						""
					)}
				</div>
			),
		});
	};

	return (
		<Fragment>
			<span className="text-sm sm:text-base font-medium font-inter">Silahkan Masukan Nominal {title}.</span>
			<Select options={nominalPintar}
				onChange={(e: any): void => {
					localStorage.setItem("deposit_nominal_input", e.value);
				}}
				placeholder="Pilih Nominal"
				className={`w-2/3 my-8 text-sm sm:text-lg font-medium font-inter text-center ${type === 5 ? "mb-8" : ''}`}
				autoFocus
			/>

			{loading
				? "loading..."
				: data?.map(
					(
						{
							vaname,
							vacode,
							desc,
						}: {
							desc: string;
							fee: number;
							provider: string;
							vacode: string;
							vaname: string;
						},
						index
					): JSX.Element => (
						<div className="w-full">
							{submitLoading ? (
								<Fragment key={index}>
									<button
										className={`${localStorage.getItem("virtual_account_list")} w-full flex items-center px-5 py-3 lg:py-4 lg:px-6 mb-4 rounded-md lg:rounded-lg content-list-shadow hover:shadow-xl lg:gap-4 cursor-not-allowed lg:transition duration-500 lg:ease-out noselect focus:outline-none`}>
										<div className="w-full flex items-center gap-3 lg:gap-4">
											<div className="w-10">
												<span className="text-xs sm:text-base font-dmsans font-medium">
													{index + 1 + "."}
												</span>
											</div>
											<div className="w-full flex items-center lg:flex-col lg:items-start lg:gap-3 xl:flex-row xl:items-center xl:gap-0">
												<div className="flex flex-col w-44 sm:w-auto lg:w-96 xl:w-auto text-left">
													<span className="mb-1 text-xs sm:text-base font-dmsans font-medium tracking-wide">{vaname}</span>
													<span className="text-xs sm:text-base font-dmsans text-gray-500 tracking-wide">{desc}</span>
												</div>
											</div>
										</div>
									</button>
								</Fragment>
							) : (
								<Fragment key={index}>
									<button
										onClick={(): void => {
											const depositNominalInput: string | null = localStorage.getItem("deposit_nominal_input");
											if (!depositNominalInput || parseInt(depositNominalInput) <= 0) {
												toast.error("Maaf Anda Harus Memasukan Nominal", {
													style: {
														fontWeight: "bolder",
													},
												});
											} else {
												toast.promise(
													handleSubmitDeposit(type, vacode, "", depositNominalInput, setSubmitLoading),
													{
														loading: "Loading...",
														success: (result: any): string => {
															const data: any = result.data.data;
															localStorage.setItem("deposit_nominal_input", "");
															responseDetails(type, data);

															return "Deposit Anda Sedang Di Proses...";
														},
														error: (e: any): string => e.data.message,
													},
													{
														style: { fontWeight: "bolder" },
													}
												);
											}
										}}
										className={`${localStorage.getItem("virtual_account_list")} w-full flex items-center px-5 py-3 lg:py-4 lg:px-6 mb-4 rounded-md lg:rounded-lg content-list-shadow hover:shadow-xl lg:gap-4 cursor-pointer lg:transition duration-500 lg:ease-out noselect focus:outline-none`}>
										<div className="w-full flex items-center gap-3 lg:gap-4">
											<div className="w-10">
												<span className="text-xs sm:text-base font-dmsans font-medium">{index + 1 + "."}</span>
											</div>
											<div className="w-full flex items-center lg:flex-col lg:items-start lg:gap-3 xl:flex-row xl:items-center xl:gap-0">
												<div className="flex flex-col w-44 sm:w-auto lg:w-96 xl:w-auto text-left">
													<span className="mb-1 text-xs sm:text-base font-dmsans font-medium tracking-wide">{vaname}</span>
													<span className="text-xs sm:text-base font-dmsans text-gray-500 tracking-wide">{desc}</span>
												</div>
											</div>
										</div>
									</button>
								</Fragment>
							)}
						</div>
					)
				)}

			<button
				onClick={(): void => {
					Swal.close();
					localStorage.removeItem("deposit_nominal_input");
				}}
				type="button"
				className="swal2-cancel text-sm sm:text-base font-inter swal2-styled mt-4"
				aria-label=""
				style={{ display: "inline-block" }}>
				Batal
			</button>
		</Fragment>
	);
};

interface NonVirtualAccountProps {
	title: string;
	type: number;
	channel: string;
	submitLoading: boolean;
	setSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const NonVirtualAccount: React.FC<NonVirtualAccountProps> = ({
	title,
	type,
	channel,
	submitLoading,
	setSubmitLoading,
}): JSX.Element => {
	const nominalPintar = [
		{ value: '10000', label: 'Rp 10.000' },
		{ value: '20000', label: 'Rp 20.000' },
		{ value: '30000', label: 'Rp 30.000' },
		{ value: '40000', label: 'Rp 40.000' },
		{ value: '50000', label: 'Rp 50.000' },
		{ value: '75000', label: 'Rp 75.000' },
		{ value: '100000', label: 'Rp 100.000' },
		{ value: '150000', label: 'Rp 150.000' },
		{ value: '200000', label: 'Rp 200.000' },
		{ value: '300000', label: 'Rp 300.000' },
		{ value: '400000', label: 'Rp 400.000' },
		{ value: '600000', label: 'Rp 600.000' },
		{ value: '700000', label: 'Rp 700.000' },
		{ value: '800000', label: 'Rp 800.000' },
		{ value: '900000', label: 'Rp 900.000' },
		{ value: '1000000', label: 'Rp 1.000.000' },
	];

	const submitDepositHandler = (
		type: number,
		vacode: string,
		channel: string,
		nominalInput: string | null,
		setSubmitLoading: React.Dispatch<React.SetStateAction<boolean>>
	): Promise<unknown> => {
		return new Promise((resolve, reject): void => {
			let urlApi: string = '';
			let payload: any = {};

			if (type === 1 || type === 2) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/send`;
				payload = {
					nominal: parseInt(nominalInput ?? ''),
					type,
				};
			}

			if (type === 4) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/payment-channel`;
				payload = {
					nominal: parseInt(nominalInput ?? ''),
					type: 6,
					vaname: 5,
				}
			}

			if (type === 5) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/payment-va`;
				payload = {
					nominal: parseInt(nominalInput ?? ''),
					vacode,
				}
			}

			if (type === 7) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/payment-link`;
				payload = {
					nominal: parseInt(nominalInput ?? ''),
					type: channel,
				}
			}

			if (type === 8) {
				urlApi = `${process.env.REACT_APP_API_ENDPOINT_URL}/deposit/payment-qris`;
				payload = {
					nominal: parseInt(nominalInput ? nominalInput : ""),
				}
			}

			setSubmitLoading(true);
			axios({
				url: urlApi,
				method: "POST",
				data: JSON.stringify(payload),
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status: any): boolean => true,
			}).then((result: any): void => {
				// 	console.log('[DEPOSIT BY ...]', result)

				if (result.status === 200) {
					setSubmitLoading(false);
					resolve(result);
				} else {
					setSubmitLoading(false);
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	function responseDetails(type: number, data: any): void {
		/*
			TYPE 1 === BANK
				TYPE 2 === EWALLET
			TYPE 4 === INDOMART
			TYPE 5 === ALFAMART
			TYPE 7 === PAYMENT LINK
			TYPE 8 === QRIS
		 */

		if (type === 7) {
			const { url_payment: urlPayment } = data;
			Swal.close();
			window.open(urlPayment, "_blank");
			return;
		}

		Swal.fire({
			padding: "1rem 0rem",
			showConfirmButton: false,
			showCancelButton: true,
			cancelButtonText: "Tutup",
			allowOutsideClick: false,
			heightAuto: false,
			customClass: {
				title: "font-inter font-semibold text-2xl text-gray-800",
				cancelButton: "text-sm sm:text-base font-inter",
				confirmButton: "text-sm sm:text-base font-inter",
			},
			html: (
				<div className="flex flex-col justify-center items-center mb-4">
					{type === 1 || type === 2 ? (
						<Fragment>
							<BankEwallet data={data} />
							<div className="mt-4">
								<span className="font-dmsans text-red-500 font-medium">Harap Transfer Sesuai Nominal</span>
							</div>
						</Fragment>
					) : type === 5 ? (
						<Fragment>
							<VirtaulAccount data={data} />
						</Fragment>
					) : type === 4 ? (
						<Fragment>
							<Alfamart data={data} />
						</Fragment>
					) : type === 8 ? (
						<Fragment>
							<Qris data={data} />
						</Fragment>
					) : (
						""
					)}
				</div>
			),
		});
	};

	return (
		<Fragment>
			<span className="text-sm sm:text-base font-medium font-inter">Silahkan Masukan Nominal {title}.</span>
			<Select options={nominalPintar}
				onChange={(e: any): void => {
					localStorage.setItem("deposit_nominal_input", e.value);
				}}
				placeholder="Pilih Nominal"
				className={`w-2/3 my-8 text-base lg:text-lg font-medium font-inter text-center ${type === 5 ? "mb-8" : ''}`}
				autoFocus
			/>
			{submitLoading ? (
				<div className="flex">
					<button
						type="button"
						className="mr-3 font-inter cursor-not-allowed border-none text-white bg-gray-500 mt-1"
						aria-label=""
						style={{
							backgroundColor: "#cccccc",
							display: "inline-block",
							marginBottom: "0.3125em",
							marginLeft: "0.3125em",
							paddingTop: "0.625em",
							paddingRight: "1.1em",
							paddingBottom: "0.625em",
							paddingLeft: "1.1em",
							borderTopLeftRadius: "0.25em",
							borderTopRightRadius: "0.25em",
							borderBottomRightRadius: "0.25em",
							borderBottomLeftRadius: "0.25em",
						}}>
						Bayar
					</button>
					<button
						type="button"
						className="mr-3 font-inter cursor-not-allowed border-none text-white mt-1"
						aria-label=""
						style={{
							backgroundColor: "#757575",
							display: "inline-block",
							marginBottom: "0.3125em",
							marginLeft: "0.3125em",
							paddingTop: "0.625em",
							paddingRight: "1.1em",
							paddingBottom: "0.625em",
							paddingLeft: "1.1em",
							borderTopLeftRadius: "0.25em",
							borderTopRightRadius: "0.25em",
							borderBottomRightRadius: "0.25em",
							borderBottomLeftRadius: "0.25em",
						}}>
						Batal
					</button>
				</div>
			) : (
				<div className="flex">
					<button
						onClick={(): void => {
							const depositNominalInput: string | null = localStorage.getItem("deposit_nominal_input");
							if (!depositNominalInput || parseInt(depositNominalInput) <= 0) {
								toast.error("Maaf Anda Harus Memasukan Nominal", {
									style: {
										fontWeight: "bolder",
									},
								});

								return;
							}

							toast.promise(
								submitDepositHandler(type, "", channel, depositNominalInput, setSubmitLoading),
								{
									loading: "Loading...",
									success: (result: any): string => {
										const statusCode: number = result.status;
										if (type === 7 || type === 8) {
											const data: any = result.data;
											if (statusCode === 200) {
												localStorage.setItem("deposit_nominal_input", "");
												responseDetails(type, data);
											}
										} else {
											const data: any = result.data.data;
											if (statusCode === 200) {
												localStorage.setItem("deposit_nominal_input", "");
												responseDetails(type, data);
											}
										}

										return "Deposit Anda Sedang Di Proses...";
									},
									error: (e: any): string => e.data.message,
								},
								{
									style: {
										fontWeight: "bolder",
									},
								}
							);
						}}
						type="button"
						className="mr-3 swal2-confirm text-sm sm:text-base font-inter swal2-styled mt-4"
						aria-label=""
						style={{ display: "inline-block" }}>
						Bayar
					</button>
					<button
						onClick={(): void => {
							Swal.close();
							localStorage.removeItem("deposit_nominal_input");
						}}
						type="button"
						className="swal2-cancel text-sm sm:text-base font-inter swal2-styled mt-4"
						aria-label=""
						style={{ display: "inline-block" }}>
						Batal
					</button>
				</div>
			)}
		</Fragment>
	);
};


interface NominalInputType {
	title: string;
	type: number;
	channel: string;
	loading: boolean;
	data: Array<any> | undefined;
}

const NominalInput: React.FC<NominalInputType> = ({
	title,
	type,
	channel,
	loading,
	data,
}): JSX.Element => {
	const [submitLoading, setSubmitLoading] = useState(false);

	return (
		<Fragment>
			<div className="flex flex-col justify-center items-center">
				{type === 5 ? (
					<VirtualAccount
						title={title}
						type={type}
						loading={loading}
						submitLoading={submitLoading}
						setSubmitLoading={setSubmitLoading}
						data={data}
					/>
				) : (
					type !== 5 && (
						<NonVirtualAccount
							title={title}
							type={type}
							channel={channel}
							submitLoading={submitLoading}
							setSubmitLoading={setSubmitLoading}
						/>
					)
				)}
			</div>
		</Fragment>
	);
};

export default NominalInput;
