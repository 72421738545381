import React, { Fragment, useEffect, useState } from "react";

import { useHistory, withRouter } from "react-router-dom";

import axios from "axios";

import loadingCardLength from "../../components/loading/loadingCardLength";
import { LandscapeLoadingCard } from "../../components/loading/LoadingCard";

import NumberFormat from "react-number-format";

import toast from "react-hot-toast";

import Header from "../../components/Header";

import ContentList from "../../components/ContentList";

import EmptyIcon from "../../components/icons/EmptyIcon";

import { DownlineEditMenu, DownlineShowMenu } from "./DownlineList";

import MoreDataList from "../../components/MoreDataList";

import ReactSweetAlert2 from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Swal: typeof ReactSweetAlert2 = withReactContent(ReactSweetAlert2);

const DownlineByDownlineListPage: React.FC = (props: any): JSX.Element => {
  if (!props.location.state) window.location.replace("/dashboard");

  const { id } = props.location.state;

  interface DownlineByDownlineListSchemaType {
    aktif: boolean;
    created_at: string;
    kode_merchant: string;
    kyc_verification: boolean;
    markup: number;
    nama: string;
    phone: string;
    poin: number;
    saldo: number;
    toko: {
      alamat: string;
      nama: string;
    };
    total_downline: number;
    updated_at: string;
    _id: string;
  }

  const downlineByDownlineListSchema = [
    {
      aktif: false,
      created_at: "",
      kode_merchant: "",
      kyc_verification: false,
      markup: 0,
      nama: "",
      phone: "",
      poin: 0,
      saldo: 0,
      toko: {
        alamat: "",
        nama: "",
      },
      total_downline: 0,
      updated_at: "",
      _id: "",
    },
  ];

  const [downlineByDownlineListState, setDownlineByDownlineListState] = useState<Array<DownlineByDownlineListSchemaType>>(downlineByDownlineListSchema);

  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);
  const [isPaginationLoadingState, setIsPaginationLoadingState] = useState<boolean>(false);
  const [isMaxPaginationState, setIsMaxPaginationState] = useState<boolean>(false);

  let [pageState, setPageState] = useState<number>(0);

  function getDownlineByDownlineList(id: string, donwlinePage: number, setLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<unknown> {
    return new Promise((resolve, reject): void => {
      setLoading(true);
      axios({
        url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/downline/list/?user_id=${id}&page=${donwlinePage}`,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
        },
        validateStatus: (status): boolean => {
          return true;
        },
      })
        .then((result: any): void => {
          // console.log('[DOWNLINE BY DOWNLINE LIST]', result)

          if (result.status === 200) {
            setLoading(false);
            resolve(result);
          } else {
            reject(result);
          }
          
        })
        .catch((error: any): void => {
          console.log("STATUS CODE", error);
        });
    });
  };

  useEffect((): void => {
    getDownlineByDownlineList(id, pageState, setIsLoadingState).then((result: any): void => {
      const statusCode: number = result.data.status;
      const data: any = result.data.data;
      if (statusCode === 200) {
        if (pageState > 0) {
          if (!data.length) {
            setIsMaxPaginationState(true);
            toast.error(`Data telah mencapai batas maximum`, {
              style: {
                fontWeight: "bolder",
              },
            });
          }
          setDownlineByDownlineListState([...downlineByDownlineListState, ...data]);
          return;
        }

        setDownlineByDownlineListState(data);
      }
    }).catch((e: any): void => {
      toast.error(e.data.message, {
        style: {
          fontWeight: "bolder",
        },
      });
      console.log(e);
    });
  }, [id, pageState]);

  const history: any = useHistory();
  const handleDownlineListShowPage = (id: string): void => {
    history.push({
      pathname: "/dashboard/profile/downline/downline/show",
      state: { id },
    });
  };

  const ScrollToTopOnMount = (): null => {
    useEffect((): void => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  };

  return (
    <div>
      <Header title="Riwayat Downline Dari Downline" />

      <ScrollToTopOnMount />

      {isLoadingState ? (
        <div className="flex flex-col gap-4">
          {loadingCardLength.slice(0, 5).map((loading, index) => (
            <Fragment key={index}>
              <LandscapeLoadingCard optional>
                <div className="h-20 lg:h-44 xl:h-20"></div>
              </LandscapeLoadingCard>
            </Fragment>
          ))}
        </div>
      ) : (
        <Fragment>
          {!downlineByDownlineListState.length ? (
            <div className="mt-24 lg:mt-20 2xl:mt-40">
              <EmptyIcon title="Member Ini Belum Memiliki Downline" />
            </div>
          ) : (
            downlineByDownlineListState.map(
              ({
                aktif,
                markup,
                nama: name,
                poin: point,
                saldo: balance,
                total_downline: downlineTotal,
                _id: id,
              }, index): JSX.Element => (
                <Fragment key={index}>
                  <ContentList
                    onClick={() =>
                      Swal.fire({
                        padding: "2rem 0rem",
                        showConfirmButton: false,
                        showCancelButton: false,
                        heightAuto: false,
                        width: 250,
                        html: (
                          <Fragment>
                            <DownlineShowMenu id={id} handleDownlineListShowPage={(): void => handleDownlineListShowPage(id)} />
                            <DownlineEditMenu id={id} name={name} markup={markup} />
                          </Fragment>
                        ),
                      })
                    }
                    optional>
                    <div className="flex flex-row gap-1 lg:gap-4 xl:flex-row w-full">
                      <div className="flex flex-1">
                        <div className="flex flex-col">
                          {/* DOWNLINE NAME */}
                          <div className="flex">
                            <div className="mr-7 lg:mr-10">
                              <span className="text-xs sm:text-base tracking-wide">Nama</span>
                            </div>
                            <div className="mb-1">
                              <span className="text-xs sm:text-base tracking-wide">{name}</span>
                            </div>
                          </div>

                          {/* DOWNLINE TOTAL */}
                          <div className="flex">
                            <div className="mr-2 lg:mr-3.5">
                              <span className="text-xs sm:text-base tracking-wide">Downline</span>
                            </div>
                            <div className="mb-1">
                              <span className="text-xs sm:text-base tracking-wide">{downlineTotal}</span>
                            </div>
                          </div>

                          {/* DOWNLINE STATUS */}
                          <div className="flex">
                            <div className="mr-6 lg:mr-9">
                              <span className="text-xs sm:text-base tracking-wide">Status</span>
                            </div>
                            <div>
                              {aktif ? (
                                <span className="text-xs sm:text-base text-green-500 tracking-wide">Aktif</span>
                              ) : (
                                <span className="text-xs sm:text-base text-red-500 tracking-wide">Non Aktif</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-1 gap-4 lg:gap-14 xl:gap-4">
                        <div className="flex flex-col">
                          {/* DOWNLINE BALANCE */}
                          <div className="flex">
                            <div className="mr-6 lg:mr-10 xl:mr-7">
                              <span className="text-xs sm:text-base tracking-wide">Saldo</span>
                            </div>
                            <div className="mb-1">
                              <NumberFormat
                                value={balance}
                                className="text-xs sm:text-base tracking-wide"
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"Rp "}
                              />
                            </div>
                          </div>

                          {/* DOWNLINE POINT */}
                          <div className="flex">
                            <div className="mr-8 lg:mr-12 xl:mr-9">
                              <span className="text-xs sm:text-base tracking-wide">Poin</span>
                            </div>
                            <div className="mb-1">
                              <span className="text-xs sm:text-base tracking-wide">{point}</span>
                            </div>
                          </div>

                          {/* DOWNLINE MARKUP */}
                          <div className="flex">
                            <div className="mr-3 lg:mr-6 xl:mr-3">
                              <span className="text-xs sm:text-base tracking-wide">Markup</span>
                            </div>
                            <div>
                              <NumberFormat
                                value={markup}
                                className="text-xs sm:text-base tracking-wide"
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"Rp "}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ContentList>
                </Fragment>
              )
            )
          )}
        </Fragment>
      )}

      {isPaginationLoadingState ? (
        <div className="flex justify-center items-center">
          <span className="text-xs sm:text-base font-medium tracking-wide">Loading...</span>
        </div>
      ) : (
        downlineByDownlineListState.length > 10 &&
        !isLoadingState && (
          <Fragment>
            {!isMaxPaginationState && <MoreDataList setPage={setPageState} setPaginationLoading={setIsPaginationLoadingState} />}
          </Fragment>
        )
      )}
    </div>
  );
};

export default withRouter(DownlineByDownlineListPage);
