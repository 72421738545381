import React, { Fragment, useEffect, useState } from "react";

import axios from "axios";

import { useRecoilValue } from "recoil";
import themeAppStore from "../../../store/theme-app.store";

import toast from "react-hot-toast";

import Header from "../../components/Header";

import "./UpdatePin.css";

interface defaultThemeSchema {
	inputWrapper: string,
	input: string,
	label: string,
	strokeIcon: string
}

const UpdatePinPage: React.FC = (props): JSX.Element => {
	const [oldPinState, setOldPinState] = useState<string>("");

	const [newPinState, setNewPinState] = useState<string>("");

	const [newRepeatPinState, setNewRepeatPinState] = useState<string>("");

	function updatePin(oldPin: string, newPin: string, newRepeatPin: string): Promise<unknown> {
		return new Promise((resolve, reject): void => {
			let payload = {
				pinLama: oldPin,
				pinBaru: newPin,
				pinConfirm: newRepeatPin,
			}

			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/pin/update`,
				method: "POST",
				data: JSON.stringify(payload),
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status: any): boolean => true,
			}).then((result: any): void => {
				// console.log('[UPDATE PIN]', result)

				if (result.status === 200) {
					setNewPinState('');
					setNewRepeatPinState('');
					setOldPinState('');
					resolve(result);
				} else {
					reject(result);
				}

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	function handleSubmitUpdatePin(e: any): void {
		e.preventDefault();
		toast.promise(
			updatePin(oldPinState, newPinState, newRepeatPinState),
			{
				loading: "Loading...",
				success: (result: any): string => result.data.message,
				error: (e: any): string => e.data.message,
			},
			{
				style: {
					fontWeight: "bolder",
				},
			}
		);
	};

	const ScrollToTopOnMount = (): null => {
		useEffect((): void => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	};

	const themeState = useRecoilValue<string | null>(themeAppStore);

	interface btnPrimarySchema {
		backgroundColor: string;
		color: string;
	}

	let btnPrimaryTheme: btnPrimarySchema = {
		backgroundColor: process.env.REACT_APP_PRIMARY_COLOR ?? "#FFFFFF",
		color: process.env.REACT_APP_PRIMARY_COLOR ? "#FFFFFF" : "#000000",
	}

	if (themeState === 'dark') {
		btnPrimaryTheme['backgroundColor'] = '#1F283E';
		btnPrimaryTheme['color'] = '#FFFFFF';
	}

	const defaultTheme: defaultThemeSchema = {
		inputWrapper: 'bg-white',
		input: 'bg-white',
		label: 'text-gray-500',
		strokeIcon: 'none'
	}

	if (themeState === 'dark') {
		defaultTheme['inputWrapper'] = 'bg-dark-menu';
		defaultTheme['input'] = 'bg-dark-menu text-gray-200';
		defaultTheme['label'] = 'text-gray-400';
		defaultTheme['strokeIcon'] = 'currentColor';
	}

	return (
		<div>
			<Header title="Ganti PIN" />

			<ScrollToTopOnMount />

			<div className="flex items-center mt-12">
				<form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmitUpdatePin(e)} className="flex flex-col w-full">
					<OldPin oldPin={oldPinState} setOldPin={setOldPinState} defaultTheme={defaultTheme} />
					<NewPin newPin={newPinState} setNewPin={setNewPinState} defaultTheme={defaultTheme} />
					<NewRepeatPin newRepeatPin={newRepeatPinState} setNewRepeatPin={setNewRepeatPinState} defaultTheme={defaultTheme} />

					<button className="inline-flex justify-center items-center gap-3 mt-4 py-3 rounded-lg shadow-md text-sm sm:text-lg lg:text-base 2xl:text-lg" style={btnPrimaryTheme}>
						<svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#FFFFFF" />
							<path d="M10.58 15.58C10.38 15.58 10.19 15.5 10.05 15.36L7.22 12.53C6.93 12.24 6.93 11.76 7.22 11.47C7.51 11.18 7.99 11.18 8.28 11.47L10.58 13.77L15.72 8.63001C16.01 8.34001 16.49 8.34001 16.78 8.63001C17.07 8.92001 17.07 9.40001 16.78 9.69001L11.11 15.36C10.97 15.5 10.78 15.58 10.58 15.58Z" fill="#FFFFFF" />
						</svg>
						Simpan
					</button>
				</form>
			</div>
		</div >
	);
};

interface NewPinProps {
	newPin: string;
	setNewPin: React.Dispatch<React.SetStateAction<string>>;
	defaultTheme: defaultThemeSchema,
}

const NewPin: React.FC<NewPinProps> = ({ newPin, setNewPin, defaultTheme }): JSX.Element => {
	return (
		<Fragment>
			<label htmlFor="new-pin" className={`${defaultTheme.label} font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide`}>Pin Baru :</label>
			<div
				className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md lg:rounded-lg mb-4`}>
				<div className="flex justify-center items-center py-3 px-4">
					<svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.18014 22.7499C6.08014 22.7499 5.97014 22.7399 5.88014 22.7299L3.71014 22.4299C2.67014 22.2899 1.73014 21.3599 1.57014 20.2999L1.27014 18.1099C1.17014 17.4099 1.47014 16.4999 1.97014 15.9899L6.36014 11.5999C5.65014 8.75992 6.47014 5.75992 8.56014 3.68992C11.8001 0.459923 17.0701 0.449923 20.3201 3.68992C21.8901 5.25992 22.7501 7.34992 22.7501 9.56992C22.7501 11.7899 21.8901 13.8799 20.3201 15.4499C18.2201 17.5299 15.2301 18.3499 12.4101 17.6299L8.01014 22.0199C7.59014 22.4599 6.84014 22.7499 6.18014 22.7499ZM14.4301 2.75992C12.6801 2.75992 10.9401 3.41992 9.61014 4.74992C7.81014 6.53992 7.16014 9.15992 7.91014 11.5999C7.99014 11.8699 7.92014 12.1499 7.72014 12.3499L3.02014 17.0499C2.85014 17.2199 2.71014 17.6599 2.74014 17.8899L3.04014 20.0799C3.10014 20.4599 3.51014 20.8899 3.89014 20.9399L6.07014 21.2399C6.31014 21.2799 6.75014 21.1399 6.92014 20.9699L11.6401 16.2599C11.8401 16.0599 12.1301 15.9999 12.3901 16.0799C14.8001 16.8399 17.4301 16.1899 19.2301 14.3899C20.5101 13.1099 21.2201 11.3899 21.2201 9.56992C21.2201 7.73992 20.5101 6.02992 19.2301 4.74992C17.9301 3.42992 16.1801 2.75992 14.4301 2.75992Z" fill="currentColor" />
						<path d="M9.19008 20.5402C9.00008 20.5402 8.81008 20.4702 8.66008 20.3202L6.36008 18.0202C6.07008 17.7302 6.07008 17.2502 6.36008 16.9602C6.65008 16.6702 7.13008 16.6702 7.42008 16.9602L9.72008 19.2602C10.0101 19.5502 10.0101 20.0302 9.72008 20.3202C9.57008 20.4702 9.38008 20.5402 9.19008 20.5402Z" fill="currentColor" />
						<path d="M14.5 11.75C13.26 11.75 12.25 10.74 12.25 9.5C12.25 8.26 13.26 7.25 14.5 7.25C15.74 7.25 16.75 8.26 16.75 9.5C16.75 10.74 15.74 11.75 14.5 11.75ZM14.5 8.75C14.09 8.75 13.75 9.09 13.75 9.5C13.75 9.91 14.09 10.25 14.5 10.25C14.91 10.25 15.25 9.91 15.25 9.5C15.25 9.09 14.91 8.75 14.5 8.75Z" fill="currentColor" />
					</svg>
				</div>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
						const re = /^[0-9\b]+$/;
						if (e.target.value === "" || re.test(e.target.value)) setNewPin(e.target.value);
					}}
					type="text"
					className={`${defaultTheme.input} password w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="new-pin"
					value={newPin ?? ''}
					maxLength={parseInt(process.env.REACT_APP_LOGIN_PIN_LENGTH ?? '')}
					placeholder="Masukan Pin baru"
				/>
			</div>
		</Fragment>
	);
};

interface OldPinProps {
	oldPin: string;
	setOldPin: React.Dispatch<React.SetStateAction<string>>;
	defaultTheme: defaultThemeSchema,
}

const OldPin: React.FC<OldPinProps> = ({ oldPin, setOldPin, defaultTheme }): JSX.Element => {
	return (
		<Fragment>
			<label htmlFor="old-pin" className={`${defaultTheme.label} font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide`}>Pin Lama :</label>
			<div
				className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md lg:rounded-lg mb-4`}>
				<div className="flex justify-center items-center py-3 px-4">
					<svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.18014 22.7499C6.08014 22.7499 5.97014 22.7399 5.88014 22.7299L3.71014 22.4299C2.67014 22.2899 1.73014 21.3599 1.57014 20.2999L1.27014 18.1099C1.17014 17.4099 1.47014 16.4999 1.97014 15.9899L6.36014 11.5999C5.65014 8.75992 6.47014 5.75992 8.56014 3.68992C11.8001 0.459923 17.0701 0.449923 20.3201 3.68992C21.8901 5.25992 22.7501 7.34992 22.7501 9.56992C22.7501 11.7899 21.8901 13.8799 20.3201 15.4499C18.2201 17.5299 15.2301 18.3499 12.4101 17.6299L8.01014 22.0199C7.59014 22.4599 6.84014 22.7499 6.18014 22.7499ZM14.4301 2.75992C12.6801 2.75992 10.9401 3.41992 9.61014 4.74992C7.81014 6.53992 7.16014 9.15992 7.91014 11.5999C7.99014 11.8699 7.92014 12.1499 7.72014 12.3499L3.02014 17.0499C2.85014 17.2199 2.71014 17.6599 2.74014 17.8899L3.04014 20.0799C3.10014 20.4599 3.51014 20.8899 3.89014 20.9399L6.07014 21.2399C6.31014 21.2799 6.75014 21.1399 6.92014 20.9699L11.6401 16.2599C11.8401 16.0599 12.1301 15.9999 12.3901 16.0799C14.8001 16.8399 17.4301 16.1899 19.2301 14.3899C20.5101 13.1099 21.2201 11.3899 21.2201 9.56992C21.2201 7.73992 20.5101 6.02992 19.2301 4.74992C17.9301 3.42992 16.1801 2.75992 14.4301 2.75992Z" fill="currentColor" />
						<path d="M9.19008 20.5402C9.00008 20.5402 8.81008 20.4702 8.66008 20.3202L6.36008 18.0202C6.07008 17.7302 6.07008 17.2502 6.36008 16.9602C6.65008 16.6702 7.13008 16.6702 7.42008 16.9602L9.72008 19.2602C10.0101 19.5502 10.0101 20.0302 9.72008 20.3202C9.57008 20.4702 9.38008 20.5402 9.19008 20.5402Z" fill="currentColor" />
						<path d="M14.5 11.75C13.26 11.75 12.25 10.74 12.25 9.5C12.25 8.26 13.26 7.25 14.5 7.25C15.74 7.25 16.75 8.26 16.75 9.5C16.75 10.74 15.74 11.75 14.5 11.75ZM14.5 8.75C14.09 8.75 13.75 9.09 13.75 9.5C13.75 9.91 14.09 10.25 14.5 10.25C14.91 10.25 15.25 9.91 15.25 9.5C15.25 9.09 14.91 8.75 14.5 8.75Z" fill="currentColor" />
					</svg>
				</div>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
						const re = /^[0-9\b]+$/;
						if (e.target.value === "" || re.test(e.target.value)) setOldPin(e.target.value);
					}}
					type="text"
					className={`${defaultTheme.input} password w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="old-pin"
					value={oldPin ? oldPin : ""}
					maxLength={parseInt(process.env.REACT_APP_LOGIN_PIN_LENGTH ?? '')}
					placeholder="Masukan Pin lama"
					autoFocus
				/>
			</div>
		</Fragment>
	);
};

interface NewRepeatPinProps {
	newRepeatPin: string;
	setNewRepeatPin: React.Dispatch<React.SetStateAction<string>>;
	defaultTheme: defaultThemeSchema,
}

const NewRepeatPin: React.FC<NewRepeatPinProps> = ({ newRepeatPin, setNewRepeatPin, defaultTheme }): JSX.Element => {
	return (
		<Fragment>
			<label htmlFor="repeat-new-pin" className={`${defaultTheme.label} font-medium text-base sm:text-lg lg:text-base 2xl:text-lg text-gray-500 mb-2 tracking-wide`}>Ulangi Pin Baru :</label>
			<div
				className={`${defaultTheme.inputWrapper} flex w-full pin-input-shadow rounded-md lg:rounded-lg mb-4`}>
				<div className="flex justify-center items-center py-3 px-4">
					<svg className="w-8 h-8" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.18014 22.7499C6.08014 22.7499 5.97014 22.7399 5.88014 22.7299L3.71014 22.4299C2.67014 22.2899 1.73014 21.3599 1.57014 20.2999L1.27014 18.1099C1.17014 17.4099 1.47014 16.4999 1.97014 15.9899L6.36014 11.5999C5.65014 8.75992 6.47014 5.75992 8.56014 3.68992C11.8001 0.459923 17.0701 0.449923 20.3201 3.68992C21.8901 5.25992 22.7501 7.34992 22.7501 9.56992C22.7501 11.7899 21.8901 13.8799 20.3201 15.4499C18.2201 17.5299 15.2301 18.3499 12.4101 17.6299L8.01014 22.0199C7.59014 22.4599 6.84014 22.7499 6.18014 22.7499ZM14.4301 2.75992C12.6801 2.75992 10.9401 3.41992 9.61014 4.74992C7.81014 6.53992 7.16014 9.15992 7.91014 11.5999C7.99014 11.8699 7.92014 12.1499 7.72014 12.3499L3.02014 17.0499C2.85014 17.2199 2.71014 17.6599 2.74014 17.8899L3.04014 20.0799C3.10014 20.4599 3.51014 20.8899 3.89014 20.9399L6.07014 21.2399C6.31014 21.2799 6.75014 21.1399 6.92014 20.9699L11.6401 16.2599C11.8401 16.0599 12.1301 15.9999 12.3901 16.0799C14.8001 16.8399 17.4301 16.1899 19.2301 14.3899C20.5101 13.1099 21.2201 11.3899 21.2201 9.56992C21.2201 7.73992 20.5101 6.02992 19.2301 4.74992C17.9301 3.42992 16.1801 2.75992 14.4301 2.75992Z" fill="currentColor" />
						<path d="M9.19008 20.5402C9.00008 20.5402 8.81008 20.4702 8.66008 20.3202L6.36008 18.0202C6.07008 17.7302 6.07008 17.2502 6.36008 16.9602C6.65008 16.6702 7.13008 16.6702 7.42008 16.9602L9.72008 19.2602C10.0101 19.5502 10.0101 20.0302 9.72008 20.3202C9.57008 20.4702 9.38008 20.5402 9.19008 20.5402Z" fill="currentColor" />
						<path d="M14.5 11.75C13.26 11.75 12.25 10.74 12.25 9.5C12.25 8.26 13.26 7.25 14.5 7.25C15.74 7.25 16.75 8.26 16.75 9.5C16.75 10.74 15.74 11.75 14.5 11.75ZM14.5 8.75C14.09 8.75 13.75 9.09 13.75 9.5C13.75 9.91 14.09 10.25 14.5 10.25C14.91 10.25 15.25 9.91 15.25 9.5C15.25 9.09 14.91 8.75 14.5 8.75Z" fill="currentColor" />
					</svg>
				</div>
				<input
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
						const re = /^[0-9\b]+$/;
						if (e.target.value === "" || re.test(e.target.value)) setNewRepeatPin(e.target.value);
					}}
					type="text"
					className={`${defaultTheme.input} password w-full py-1 lg:py-3 text-sm sm:text-lg lg:text-base 2xl:text-lg tracking-wider rounded-md focus:outline-none`}
					id="repeat-new-pin"
					value={newRepeatPin ?? ''}
					maxLength={parseInt(process.env.REACT_APP_LOGIN_PIN_LENGTH ?? '')}
					placeholder="Masukan ulang Pin baru"
				/>
			</div>
		</Fragment>
	);
};

export default UpdatePinPage;
