import React, { Fragment } from "react";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// LAYOUTS
import AuthenticatedLayout from "../views/MemberLayout";
import GuestLayout from "../views/GuestLayout";

// MIDDLEWARE
import Guest from "../middleware/Guest";
import Authenticated from "../middleware/Authenticated";

// GUEST PAGES
import LoginPage from "../views/pages/Login";
import OtpSendPage from "../views/pages/SendOtp";

// AUTHENTICATED COMPONENTS
import SidebarMenu from "../views/components/sidebar/SidebarMenu";
import { Navigation, MobileNavigation } from "../views/components/Navigation";
import HomeMenu from "../views/components/HomeMenu";

// AUTHENTICATED PAGES
import UserInfoDetailsPage from "../views/pages/profile/UserDetails";

/// PRODUCT TYPE PAGES
import PulsaPage from "../views/pages/products/prepaid/Pulsa";
import PrepaidDenomPage from "../views/pages/products/prepaid/PrepaidDenom";
import PostpaidDenomPage from "../views/pages/products/postpaid/PostpaidDenom";

/// SUBMENU
import SubmenuListPage from "../views/pages/submenu/SubmenuList";
import SubmenuGridPage from "../views/pages/submenu/SubmenuGrid";

/// HISTORY
import TrxListPage from "../views/pages/history/trx/TrxList";
import TrxListDetailsPage from "../views/pages/history/trx/TrxListDetails";
import DepositListPage from "../views/pages/history/deposit/DepositList";
import DepositListDetailsPage from "../views/pages/history/deposit/DepositListDetails";
import MutationListPage from "../views/pages/history/MutationList";
import CommitionListPage from "../views/pages/commition/CommitionList";
import RewardListPage from "../views/pages/history/RewardList";

/// PROFILE
import UpdatePinPage from "../views/pages/profile/UpdatePin";

import RewardGridPage from "../views/pages/reward/RewardGrid";

import DownlineListPage from "../views/pages/downline/DownlineList";
import DownlineByDownlinePage from "../views/pages/downline/DownlineByDownlineList";
import DownlineAddPage from "../views/pages/downline/DownlineAdd";

// DEPOSIT // TOPUP
import PaymentMethodListPage from "../views/pages/deposit/PaymentMethodList";

import TrxNotificationListPage from "../views/pages/history/TrxNotificationList";
import FavoriteNumberPage from "../views/pages/products/FavoriteNumber";

import CustomerServicePage from "../views/pages/customer-service/CustomerService";

// ERROR
import Error404 from "../views/pages/404";
import TrxPrint from "../views/pages/history/trx/print/TrxPrint";

const Routes: React.FC = (props): JSX.Element => {
	return (
		<BrowserRouter>
			<Switch>
				{/* ROUTE TYPE: AUTH */}

				{/* LOGIN ROUTE */}
				<Route exact path="/">
					<Redirect to="/auth/login" />
				</Route>

				{/* LOGIN ROUTE */}
				<Route path="/auth/login">
					<Guest>
						<GuestLayout title={process.env.REACT_APP_LOGIN_TITLE}>
							<LoginPage />
						</GuestLayout>
					</Guest>
				</Route>

				{/* SEND OTP CODE ROUTE */}
				<Route path="/auth/send-otp">
					<Guest>
						<GuestLayout title="Kirim Kode OTP">
							<OtpSendPage />
						</GuestLayout>
					</Guest>
				</Route>

				{/* DASHBOARD ROUTE */}
				<Route exact path="/dashboard">
					<Authenticated>
						<AuthenticatedLayout
							title={process.env.REACT_APP_TITLE}
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<HomeMenu />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* ROUTE TYPE: USER PROFILE */}

				{/* USER TRX SHOW ALL NOTIFICATION LIST */}
				<Route path="/dashboard/profile/trx/notification/show">
					<Authenticated>
						<AuthenticatedLayout
							title="Seluruh Notifikasi Transaksi"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<TrxNotificationListPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* USER TOPUP */}
				<Route path="/dashboard/profile/user/topup">
					<Authenticated>
						<AuthenticatedLayout
							title="Metode Pembayaran Isi Saldo"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<PaymentMethodListPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* USER INFO */}
				<Route path="/dashboard/profile/user/info">
					<Authenticated>
						<AuthenticatedLayout
							title="Detail Informasi User"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<UserInfoDetailsPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* USER EDIT PIN */}
				<Route path="/dashboard/profile/user/pin/update">
					<Authenticated>
						<AuthenticatedLayout
							title="Ganti Pin"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<UpdatePinPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* DOWNLINE USER LIST */}
				<Route exact path="/dashboard/profile/downline">
					<Authenticated>
						<AuthenticatedLayout
							title="Riwayat Downline"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<DownlineListPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* SHOW DOWNLINE USER BY DOWNLINE */}
				<Route exact path="/dashboard/profile/downline/downline/show">
					<Authenticated>
						<AuthenticatedLayout
							title="Riwayat Downline Dari Downline"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<DownlineByDownlinePage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* DOWNLINE USER ADD */}
				<Route path="/dashboard/profile/downline/add">
					<Authenticated>
						<AuthenticatedLayout
							title="Tambah Downline"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<DownlineAddPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* ROUTE TYPE: TRANSACTION MENU */}

				{/*  PULSA PULSA */}
				<Route path="/dashboard/trx/favorite-number">
					<Authenticated>
						<AuthenticatedLayout
							title="Nomor Favorit"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<FavoriteNumberPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/*  PULSA PULSA */}
				<Route path="/dashboard/trx/pulsa">
					<Authenticated>
						<AuthenticatedLayout
							title="Transaksi Pulsa"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<PulsaPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* VOUCHER PURCHASE TRANSACTION */}
				<Route path="/dashboard/trx/detail-denom">
					<Authenticated>
						<AuthenticatedLayout
							title="Pembelian Voucher"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<PrepaidDenomPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* BILLING TRANSACTIONS */}
				<Route path="/dashboard/trx/detail-denom-postpaid">
					<Authenticated>
						<AuthenticatedLayout
							title="Pembayaran Tagihan"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<PostpaidDenomPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* PRODUCT SUBMENU LIST */}
				<Route path="/dashboard/trx/list-submenu">
					<Authenticated>
						<AuthenticatedLayout
							title="List Jenis Produk"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<SubmenuListPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* PRODUCT SUBMENU GRID */}
				<Route path="/dashboard/trx/list-gridmenu">
					<Authenticated>
						<AuthenticatedLayout
							title="List Submenu Produk"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<SubmenuGridPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* ROUTE TYPE: PROFILE HISTORY */}

				{/* TRX HISTORY LIST */}
				<Route exact path="/dashboard/profile/history/trx">
					<Authenticated>
						<AuthenticatedLayout
							title="Riwayat Transaksi"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<TrxListPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* TRX HISTORY SHOW DETAILS */}
				<Route path="/dashboard/profile/history/trx/show">
					<Authenticated>
						<AuthenticatedLayout
							title="Riwayat Transaksi"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<TrxListDetailsPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* PRINT TRX HISTORY LIST */}
				<Route path="/dashboard/profile/history/trx/print">
					<Authenticated>
						<TrxPrint />
					</Authenticated>
				</Route>

				{/* DEPOSIT HISTORY LIST */}
				<Route exact path="/dashboard/profile/history/deposit">
					<Authenticated>
						<AuthenticatedLayout
							title="Riwayat Deposit"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<DepositListPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* DEPOSIT HISTORY SHOW DETAILS */}
				<Route path="/dashboard/profile/history/deposit/show">
					<Authenticated>
						<AuthenticatedLayout
							title="Riwayat Deposit"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<DepositListDetailsPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* MUTATION HISTORY LIST */}
				<Route path="/dashboard/profile/history/mutation">
					<Authenticated>
						<AuthenticatedLayout
							title="Riwayat Mutasi"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<MutationListPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* MUTATION HISTORY LIST */}
				<Route path="/dashboard/profile/history/reward">
					<Authenticated>
						<AuthenticatedLayout
							title="Riwayat Mutasi"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<RewardListPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* ROUTE TYPE: REWARD PROFILE */}

				{/* COMMITION LIST */}
				<Route path="/dashboard/profile/history/commition">
					<Authenticated>
						<AuthenticatedLayout
							title="Riwayat Komisi"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<CommitionListPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* REWARD LIST */}
				<Route path="/dashboard/profile/reward">
					<Authenticated>
						<AuthenticatedLayout
							title="Daftar Reward"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<RewardGridPage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* ROUTE: HELPER */}

				{/* CUSTORMER SERVICE */}
				<Route path="/dashboard/customer-service">
					<Authenticated>
						<AuthenticatedLayout
							title="Pusat Bantuan"
							sidebar={<SidebarMenu />}
							navigation={
								<Fragment>
									<Navigation />
									<MobileNavigation />
								</Fragment>
							}
						>
							<CustomerServicePage />
						</AuthenticatedLayout>
					</Authenticated>
				</Route>

				{/* ROUTE TYPE: ERROR */}

				{/* ROUTE ERROR HANDLER */}
				<Route path="*">
					<GuestLayout title="Halaman Tidak Di Temukan">
						<Error404 />
					</GuestLayout>
				</Route>
			</Switch>
		</BrowserRouter>
	);
};

export default Routes;
