import React, { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useRecoilValue } from "recoil";
import submenuStore from "../../../store/submenu.store";

import EmptyIcon from "../../components/icons/EmptyIcon";

import ContentList from "../../components/ContentList";
import ContentGrid from "../../components/ContentGrid";

interface SubmenuProps {
	display?: string;
	backgroundImage?: boolean;
	dataSearch?: string;
}

const Submenu: React.FC<SubmenuProps> = ({
	display = "flex flex-col gap-4",
	backgroundImage = false,
	dataSearch = "",
}): JSX.Element => {
	const menuIcon: string | null = localStorage.getItem("menu_icon");

	const submenuState = useRecoilValue(submenuStore);

	const history = useHistory();

	function handleMenuRedirect(
		jenis: number,
		productCode: number,
		inputMenu: string,
		name: string,
		type: number,
		id: string,
		categoryId?: string,
	) {
		let productType = '';
		let pathName = '';

		if (jenis === 1) {
			productType = 'prepaid';
			pathName = '/dashboard/trx/pulsa';
		}
		if (jenis === 2) {
			if (categoryId && type === 1) {
				productType = 'prepaid';
				pathName = '/dashboard/trx/detail-denom';
			}
			if (productCode && type === 2) {
				productType = 'postpaid';
				pathName = '/dashboard/trx/detail-denom-postpaid';
			}
			if (!categoryId) {
				productType = '/dashboard/trx/list-submenu';
				pathName = '/dashboard/trx/list-submenu';
			}
		}
		if (jenis === 4) pathName = '/dashboard/trx/list-gridmenu';

		history.push({
			pathname: pathName,
			state: { id, name, inputMenu, productCode, productType, categoryId },
		});
	};

	interface SearchResultSchemaType {
		category_id?: string;
		description: string;
		icon: string;
		jenis: number;
		kode_produk: number;
		menu_input: string;
		name: string;
		type: number;
		_id: string;
	}

	const searchResultSchema = [
		{
			category_id: "",
			description: "",
			icon: "",
			jenis: 0,
			kode_produk: 0,
			menu_input: "",
			name: "",
			type: 0,
			_id: "",
		},
	];

	const [searchResultState, setRearchResultState] = useState<Array<SearchResultSchemaType>>(searchResultSchema);

	useEffect((): void => {
		const search: Array<SearchResultSchemaType> = submenuState.filter(({ name }) => name.toLowerCase().includes(dataSearch.toLowerCase()));
		setRearchResultState(search);
	}, [dataSearch, submenuState]);

	return (
		<Fragment>
			{!searchResultState.length ? (
				<div className="flex justify-center mt-24 lg:mt-20 2xl:mt-40">
					<EmptyIcon title="Menu Yang Anda Cari Tidak Ditemukan" />
				</div>
			) : (
				<div className={`${display === "grid" && "grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 lg:gap-4"}`}>
					{searchResultState.map(({
						category_id: categoryId,
						description,
						icon,
						jenis,
						kode_produk: productCode,
						menu_input: inputMenu,
						name,
						type,
						_id: id,
					}, index): JSX.Element =>
						backgroundImage ? (
							<Fragment key={index}>
								<ContentGrid
									onClick={(): void => handleMenuRedirect(jenis, productCode, inputMenu, name, type, id, categoryId)}
									bgImage={icon}
									title={name}
								/>
							</Fragment>
						) : (
							<Fragment key={index}>
								<ContentList
									onClick={(): void => handleMenuRedirect(jenis, productCode, inputMenu, name, type, id, categoryId)}
									line={2}
									icon={<img className="w-9 h-9 sm:w-14 sm:h-14 rounded-sm" src={menuIcon ?? ''} alt="icon" />}
									firstLine={<span className="mb-1 text-sm sm:text-base font-medium tracking-wide">{name}</span>}
									secondLine={<span className="text-xs sm:text-base font-base tracking-wide">{description}</span>}
								/>
							</Fragment>
						))}
				</div>
			)}
		</Fragment>
	);
};

export default Submenu;
