import { Fragment, useEffect, useState } from "react";

import { useHistory, withRouter } from "react-router-dom";

import axios from "axios";

import { useRecoilValue, useSetRecoilState } from "recoil";
import themeAppStore from "../store/theme-app.store";
import dropdownNotificationStore from "../store/dropdown-notification.store";
import dropdownStore from "../store/dropdown.store";
import sidebarStore from "../store/sidebar.store";
import deviceDetectStore from "../store/device-detect.store";

import ToastOffline from "./components/ToastOffline";

import { Helmet, HelmetProvider } from "react-helmet-async";

import BannerList from "./components/BannerList";
import InfoList from "./components/InfoList";

import "./MemberLayout.css";

const MemberLayout = (props: any): JSX.Element => {
	const { pathname } = props.location;

	const themeState = useRecoilValue<string | null>(themeAppStore);

	const setIsDropdownNotificationState = useSetRecoilState(dropdownNotificationStore);

	const setIsDropdownState = useSetRecoilState<boolean>(dropdownStore);

	const isSidebarInterfaceState: boolean = useRecoilValue<boolean>(sidebarStore);

	const menuWrapperTheme: string = themeState === "dark" ? "lg:bg-gray-800" : "";

	const contentInterface: string = isSidebarInterfaceState ? "minimalContent" : "maximalContent";

	const [listState, setListState] = useState<boolean>(true);

	useEffect((): void => {
		const matchMedia: any = window.matchMedia("(max-width: 1024px)");

		function mediaQuery(matchMedia: any): void {
			if (matchMedia.matches) {
				if (pathname !== "/dashboard")
					setListState(false);
				else
					setListState(true);
			} else {
				setListState(true);
			}

			if (pathname === "/dashboard/profile/user/info") 
				setListState(false);
		};

		mediaQuery(matchMedia);

		matchMedia.addListener(mediaQuery);
	}, [pathname]);

	function sendDeviceToken(tokenValue: string | null): Promise<unknown> {
		return new Promise((resolve, reject) => {
			axios({
				url: `${process.env.REACT_APP_API_ENDPOINT_URL}/user/device_token`,
				method: "POST",
				data: JSON.stringify({
					token: tokenValue,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: localStorage.getItem("user_token"),
				},
				validateStatus: (status): boolean => true,
			}).then((result: any): void => {
				// console.log("[SEND DEVICE TOKEN]", result);

				if (result.status === 200)
					return resolve(result);
				else
					return reject(result);

			}).catch((error: any): void => console.log("STATUS CODE", error));
		});
	};

	// SEND DEVICE TOKEN
	useEffect((): void => {
		const deviceToken = localStorage.getItem("device_token");
		sendDeviceToken(deviceToken);
	}, []);

	const deviceDetectStatusState = useRecoilValue<number>(deviceDetectStore);

	const history = useHistory();
	if (deviceDetectStatusState !== 200) {
		localStorage.clear();
		history.push("/auth/login");
	}

	return (
		<Fragment>
			<HelmetProvider>
				<Helmet>
					<title>{props.title}</title>
					<link rel="icon" href={process.env.REACT_APP_FAVICON} />
				</Helmet>
			</HelmetProvider>

			<div className="min-h-screen flex">
				{/* SIDEBAR COMPONENT */}
				{props.sidebar}
				<div className="w-full">
					{/* NAVIGATION COMPONENT */}
					{props.navigation}
					<div
						onClick={(): void => {
							setIsDropdownNotificationState(false);
							setIsDropdownState(false);
						}}>
						<div
							id="content"
							className={`${listState ? `${menuWrapperTheme} ${contentInterface} min-h-screen block lg:flex gap-8 rounded-sm` : contentInterface}`}>
							<div className="w-full">{props.children}</div>
							{listState && (
								<div className="w-full lg:w-72 2xl:w-80">
									{/* BANNER LIST COMPONENT */}
									<BannerList />

									{/* BANNER INFO COMPONENT */}
									<InfoList />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<ToastOffline />
		</Fragment>
	);
};

export default withRouter(MemberLayout);
